import { createBrowserRouter } from "react-router-dom";
import Home from "../page/home/Home";
import TheLayout from "../container/TheLayout";

export const appRoutes = createBrowserRouter([
  {
    path: "/",
    element: <TheLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
    ],
  },
]);
