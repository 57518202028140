import React, { Fragment, memo } from "react";
import { IMAGES } from "../../../assets/image";
import { Box, Grid } from "@mantine/core";
import classes from "./whyChoose.module.scss";
import WhyChooseCard from "./components/WhyChooseCard";
import WhyChooseHeader from "./components/WhyChooseHeader";
import { ICON_IMG } from "../../../assets/image/icons";
import { MContainer } from "../../../ui";

const WhyChoose = () => {
  return (
    <Fragment>
      <WhyChooseHeader />
      <MContainer position="relative">
        <Grid py={20} style={{ zIndex: 9999 }}>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.support_icon} alt="" width={28} />}
              count={"01"}
              title={"24/7 Support"}
              description={
                "We are providing Live chat, ticket and Telegram Based support for our customers. Our support team available for 24/7 in 3 shifts and Customer support we provide in many different languages."
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.secure_icon} alt="" width={28} />}
              count={"02"}
              title={"Secure"}
              description={
                "Secure When we say your money is safe, we really mean it. We've built some of the world's most sophisticated Crypto security systems to provide you peace of mind. 98% of Funds stored in Multi-sig Cold Walletsp"
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.bottom_border} ${classes.display_none}`}
          >
            <Box>
              <img src={IMAGES.why_choses_student} alt="" width={"100%"} />
            </Box>
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.like_icon} alt="" width={28} />}
              count={"03"}
              title={"Handled"}
              description={
                "We are early industry pioneers and have been around since 2014, successfully processed more than 100 millions worth of transactions and have happy customers all around world."
              }
            />
          </Grid.Col>
          <Grid.Col
            span={{ base: 12, sm: 12, md: 4 }}
            className={`${classes.right_border}`}
          >
            <WhyChooseCard
              icon={<img src={ICON_IMG.like_icon} alt="" width={28} />}
              count={"04"}
              title={"Advanced"}
              description={
                "The simple interface and quick charting conversion facilities help traders enjoy a silky-smooth experience. People love our easy-to-use user interface and We make your crypto experience best in class."
              }
            />
          </Grid.Col>
          <Grid.Col span={{ base: 12, sm: 12, md: 4 }}>
            <WhyChooseCard
              icon={<img src={ICON_IMG.global_icon} alt="" width={28} />}
              count={"05"}
              title={"Global Expertise"}
              description={
                "Our expert team has worked all over the world for top tech and finance companies Plus we’re backed by some of the world's top investors."
              }
            />
          </Grid.Col>
        </Grid>
        <Box className={classes.bgContainer}>
          <img src={IMAGES.why_choses_bottom_bg} alt="" width={"100%"} />
        </Box>
      </MContainer>
    </Fragment>
  );
};

export default memo(WhyChoose);
