import React from "react";
import "./App.css";
import { MantineProvider } from "@mantine/core";
import { RouterProvider } from "react-router-dom";
import { appRoutes } from "./router/appRouter";

function App() {
  return (
    <MantineProvider>
      <RouterProvider router={appRoutes} />
    </MantineProvider>
  );
}

export default App;
