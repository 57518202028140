const quickLink = [
  {
    label: "Market Maker Program",
    link: "/",
  },
  {
    label: "Referral Program",
    link: "/",
  },
  {
    label: "Coin Info",
    link: "/",
  },
  {
    label: "Coin Status",
    link: "/",
  },
];
const policyLink = [
  {
    label: "Privacy Policy",
    link: "/",
  },
  {
    label: "AML Policy",
    link: "/",
  },
  {
    label: "Cookie Statement",
    link: "/",
  },
  {
    label: "Risk Warning",
    link: "/",
  },
  {
    label: "Terms Of Use",
    link: "/",
  },
  {
    label: "Refund Policy",
    link: "/",
  },
];

const companyLink = [
  {
    label: "Who we are",
    link: "/",
  },
  {
    label: "Contact us",
    link: "/",
  },
  {
    label: "White Paper",
    link: "/",
  },
  {
    label: "API Documentation",
    link: "/",
  },
];

const supportLink = [
  {
    label: "24/7 chat support",
    link: "/",
  },
  {
    label: "How it works",
    link: "/",
  },
  {
    label: "FAQ",
    link: "/",
  },
  {
    label: "List Your Token / Coin",
    link: "/",
  },
  {
    label: "Corporate Account",
    link: "/",
  },
  {
    label: "Trading Guide",
    link: "/",
  },
  {
    label: "Law Enforcement Request",
    link: "/",
  },
];

export const footerLink = {
  quickLink: quickLink,
  policyLink: policyLink,
  companyLink: companyLink,
  supportLink: supportLink,
};
