import { Box } from "@mantine/core";
import React from "react";
import classes from "./JourneyNow.module.scss";
import { MButtons, MContainer, MTypography } from "../../../ui";
import { IMAGES } from "../../../assets/image";

const JourneyNow = () => {
  return (
    <Box>
      <Box className={classes.root}>
        <MContainer paddingTop={0}>
          <Box className={classes.container}>
            <Box className={classes.flexContainer}>
              <Box className={classes.widthContainer}>
                <MTypography
                  variant="heading_42"
                  lineHeight={"50px"}
                  marginBottom={6}
                >
                  <span className={classes.lightFontWeight}>Start Your</span>{" "}
                  Crypto Journey Now!
                </MTypography>
                <MTypography variant="description_16" marginBottom={16}>
                  With the{" "}
                  <span style={{ fontWeight: 600 }}>USDTTRADER App</span> and
                  website, trading has been easier.
                </MTypography>
                <MButtons label="Sing Up" />
              </Box>
              <Box className={classes.imageContainer}>
                <img
                  src={IMAGES.journeyNow.girl_rock}
                  alt="girl_rock"
                  className={classes.girlRock}
                />
                <img
                  src={IMAGES.journeyNow.woman_share}
                  alt="woman_share"
                  className={classes.womanShareImage}
                />
              </Box>
            </Box>
          </Box>
        </MContainer>
      </Box>
      <Box className={classes.appContainer}>
        <MContainer>
          <MTypography variant="heading_42" align="center" marginBottom={18}>
            The World’s <span style={{ fontWeight: 300 }}>Fastest Growing</span>{" "}
            <br /> USDTTRADER App
          </MTypography>
          <MTypography variant="description_16" align="center">
            Join 10m+ users buying and selling 250+ cryptocurrencies at true
            cost
          </MTypography>
        </MContainer>
      </Box>
    </Box>
  );
};

export default JourneyNow;
