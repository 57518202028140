import logo from "./logo_usdt.png";
import circle_gradient from "./hero-banner/circle-gradient.png";
import play_store from "./hero-banner/play-store.png";
import home_banner_right from "./hero-banner/home-banner-right.webp";
// play store section
import playStore_bg from "./play-store/play-store-bg.png";
import mobile_play_storeImg from "./play-store/ph-tl.5e9eaf9ab2ecb9cf73c6.webp";
import qr_play_store from "./play-store/qr_play_store.png";
import google_store_vertical from "./play-store/google-store-v.webp";
import apple_store_vertical from "./play-store/apple-store-v.webp";
// why choose on Home
import why_choses_top_bg from "./why-choose/why_choses_top_bg.png";
import why_choses_bottom_bg from "./why-choose/why_choses_bottom_bg.png";
import why_choses_student from "./why-choose/why_choses_student.webp";
// carousel on home
import carousel_1_1 from "./carousel/carousel-1.1.png";
import carousel_1_2 from "./carousel/carousel-1.2.png";
import carousel_1_3 from "./carousel/carousel-1.3.png";
import carousel_2_1 from "./carousel/carousel-2.1.png";
import carousel_2_2 from "./carousel/carousel-2.2.png";
import carousel_2_3 from "./carousel/carousel-2.3.png";
import carousel_2_4 from "./carousel/carousel-2.4.png";
import carousel_3_1 from "./carousel/carousel-3.1.png";
import carousel_4_1 from "./carousel/carousel-4.1.png";
import carousel_4_2 from "./carousel/carousel-4.2.png";
import { investing } from "./investing";
import { journeyNow } from "./journey-now";
export const IMAGES = {
  logo,
  circle_gradient,
  play_store,
  home_banner_right,
  // play store section
  playStore_bg,
  mobile_play_storeImg,
  qr_play_store,
  google_store_vertical,
  apple_store_vertical,
  // why choose on Home
  why_choses_top_bg,
  why_choses_bottom_bg,
  why_choses_student,
  // carousel on home
  carousel_1_1,
  carousel_1_2,
  carousel_1_3,
  carousel_2_1,
  carousel_2_2,
  carousel_2_3,
  carousel_2_4,
  carousel_3_1,
  carousel_4_1,
  carousel_4_2,
  investing: investing,
  journeyNow: journeyNow,
};
