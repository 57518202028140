import { Box, Grid } from "@mantine/core";
import React from "react";
import { MButtons, MContainer, MTypography } from "../../../ui";
import classes from "./Investing.module.scss";
import { IMAGES } from "../../../assets/image";

const Investing = () => {
  return (
    <MContainer>
      <Box className={classes.root}>
        <Box mb={40}>
          <Box className={classes.flexContainer}>
            <Box className={classes.leftWidthContainer}>
              <MTypography variant="heading_42" tag="h2" lineHeight={"initial"}>
                Investing in Crypto{" "}
                <span className={classes.liteFontWeight}>will be Easier</span>{" "}
                than Ever
              </MTypography>
            </Box>
            <Box className={classes.rightWidthContainer}>
              <Box className={classes.buttonContainer}>
                <MButtons label="Get started" />
              </Box>
              <MTypography variant="description_16" fontWeight={600}>
                <span>
                  <img
                    src={IMAGES.investing.party_popper}
                    alt=""
                    className={classes.partyPopperImage}
                  />
                </span>
                Sign <span className={classes.liteFontWeight}>Up with</span>{" "}
                USDTTRADER!{" "}
                <span className={classes.liteFontWeight}>enjoy Superfast</span>{" "}
                <br /> Trading{" "}
                <span className={classes.liteFontWeight}> Experience</span>
              </MTypography>
            </Box>
          </Box>
        </Box>
        <Box>
          <Grid>
            <Grid.Col span={{ base: 12, lg: 4 }}>
              <Box className={classes.colorContainer}>
                <Box className={classes.registerContainer}>
                  <Box>
                    <img
                      src={IMAGES.investing.file}
                      alt=""
                      className={classes.icons}
                    />
                    <MTypography
                      variant="subHeading_32"
                      tag="h2"
                      marginBottom={0}
                    >
                      Register
                    </MTypography>
                    <MTypography
                      variant="description_16"
                      tag="h2"
                      marginBottom={38}
                      color="gray_a1a1a1"
                    >
                      Sign Up with your mobile number
                    </MTypography>
                    <MButtons label="Register Now" />
                  </Box>

                  <Box className={classes.womanImageContainer}>
                    <img
                      src={IMAGES.investing.woman_phone}
                      alt="woman_phone"
                      className={classes.womanImage}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid.Col>
            <Grid.Col span={{ base: 12, lg: 7.5 }}>
              <Box className={classes.colorContainer}>
                <Box className={classes.row}>
                  <Box>
                    <img
                      src={IMAGES.investing.pig_bank}
                      alt="pig_bank"
                      className={classes.icons}
                    />
                    <MTypography
                      variant="subHeading_32"
                      tag="h2"
                      marginBottom={0}
                    >
                      Fund
                    </MTypography>
                    <MTypography
                      variant="description_16"
                      tag="h2"
                      marginBottom={38}
                      color="gray_a1a1a1"
                    >
                      Funds from your bank account
                    </MTypography>
                  </Box>
                  <Box>
                    <img
                      src={IMAGES.investing.bank}
                      alt="bank"
                      width={"100%"}
                    />
                  </Box>
                </Box>
              </Box>
              <Box
                className={`${classes.colorContainer} ${classes.tradeContainer}`}
                style={{ paddingBottom: 44 }}
              >
                <Box className={classes.row}>
                  <Box>
                    <img
                      src={IMAGES.investing.bar}
                      alt="bar"
                      className={classes.icons}
                    />
                    <MTypography
                      variant="subHeading_32"
                      tag="h2"
                      marginBottom={0}
                    >
                      Trade
                    </MTypography>
                    <MTypography
                      variant="description_16"
                      tag="h2"
                      marginBottom={38}
                      color="gray_a1a1a1"
                    >
                      Buy, sell and transfer Bitcoin, Ether and 100+ Crypto
                      assets.
                    </MTypography>
                  </Box>
                  <Box className={classes.tradingImageContainer}>
                    <img
                      src={IMAGES.investing.trading}
                      alt="bank"
                      className={classes.tradingImage}
                    />
                  </Box>
                </Box>
              </Box>
            </Grid.Col>
          </Grid>
        </Box>
        <Box className={classes.bgContainer}>
          <img src={IMAGES.investing.bg} alt="" className={classes.bgImage} />
        </Box>
      </Box>
    </MContainer>
  );
};

export default Investing;
