import React, { memo } from "react";
import { Grid } from "@mantine/core";
import { MContainer, MImgCarousel } from "../../../ui";
import { IMAGES } from "../../../assets/image";

const SliderSection = () => {
  return (
    <MContainer>
      <Grid my={40}>
        <Grid.Col
          span={{ base: 12, xs: 6, sm: 4, md: 3, lg: 3 }}
          style={{ color: "red" }}
        >
          <MImgCarousel
            title={"Crypto Basics"}
            data={[
              { src: IMAGES.carousel_1_1, alt: "" },
              { src: IMAGES.carousel_1_2, alt: "" },
              { src: IMAGES.carousel_1_3, alt: "" },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 3, lg: 3 }}>
          <MImgCarousel
            title={"Listed Tokens"}
            data={[
              { src: IMAGES.carousel_2_1, alt: "" },
              { src: IMAGES.carousel_2_2, alt: "" },
              { src: IMAGES.carousel_2_3, alt: "" },
              { src: IMAGES.carousel_2_4, alt: "" },
            ]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 3, lg: 3 }}>
          <MImgCarousel
            title={"Crypto Rewards"}
            data={[{ src: IMAGES.carousel_3_1, alt: "" }]}
          />
        </Grid.Col>
        <Grid.Col span={{ base: 12, xs: 6, sm: 4, md: 3, lg: 3 }}>
          <MImgCarousel
            title={"Announcement"}
            data={[
              { src: IMAGES.carousel_1_1, alt: "" },
              { src: IMAGES.carousel_1_2, alt: "" },
              { src: IMAGES.carousel_1_3, alt: "" },
            ]}
          />
        </Grid.Col>
      </Grid>
    </MContainer>
  );
};

export default memo(SliderSection);
