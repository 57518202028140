import React from "react";
import { Button } from "@mantine/core";
import { COLORS } from "../../assets";
import classes from "./MButtons.module.scss";

interface IProps {
  label: string;
  // bg?: keyof typeof COLORS;
}

const MButtons = (props: IProps) => {
  const {
    label,
    // bg = "primary",
  } = props;
  return (
    <button
      // style={{
      //   background: COLORS[bg],
      //   padding: padding,
      //   border: "none",
      //   borderRadius: 5,
      //   color: COLORS[textColor],
      //   fontSize: 14,
      //   fontWeight: 500,
      // }}
      className={classes.btn}
    >
      <span className={classes.span}> {label}</span>
      <span className={classes.span}> {label}</span>
    </button>
  );
};

export default MButtons;
