import React, { Fragment } from "react";
import HeroBanner from "../../components/home-page/hero-banner/HeroBanner";
import PlayStore from "../../components/home-page/play-store/PlayStore";
import WhyChoose from "../../components/home-page/why-choose/WhyChoose";
import SliderSection from "../../components/home-page/slider/SliderSection";
import Investing from "../../components/home-page/investing/Investing";
import JourneyNow from "../../components/home-page/journey-now/JourneyNow";

const Home = () => {
  return (
    <Fragment>
      <HeroBanner />
      <SliderSection />
      <Investing />
      <WhyChoose />
      <JourneyNow />
      <PlayStore />
    </Fragment>
  );
};

export default Home;
