import React from "react";
import classes from "./HeroBanner.module.scss";
import { Box, BackgroundImage } from "@mantine/core";
import { IMAGES } from "../../../assets/image";
import { MButtons, MTypography } from "../../../ui";
import { COLORS } from "../../../assets";

const HeroBanner = () => {
  return (
    <BackgroundImage src={IMAGES.circle_gradient} className={classes.bgImage}>
      <Box className={classes.root}>
        <Box className={classes.widthContainer}>
          <MTypography
            variant="description_16"
            tag="h3"
            fontWeight={500}
            marginBottom={8}
          >
            Together we grow
          </MTypography>
          <MTypography
            variant="title_72"
            tag="h3"
            fontWeight={500}
            marginBottom={8}
            lineHeight={"82px"}
          >
            Trusted Bitcoin & Crypto{" "}
            <span style={{ color: COLORS.primary }}>Trading</span> Platform
          </MTypography>
          <MTypography
            variant="description_16"
            tag="p"
            fontWeight={400}
            marginBottom={16}
          >
            Trade Bitcoin, Ethereum and
            <span style={{ color: COLORS.primary }}>150+ cryptos</span> in
            seconds, on our simple and ultra-secure crypto exchange.
          </MTypography>
          <MButtons label="Get started" />
          <Box className={classes.imageContainer}>
            <a
              href="https://play.google.com/store/apps/details?id=com.usdttrader&pli=1"
              target="_blank"
            >
              <img
                src={IMAGES.play_store}
                alt="play_store"
                className={classes.Image}
              />
            </a>
          </Box>
        </Box>
        <Box className={classes.rightWidthContainer}>
          <img
            src={IMAGES.home_banner_right}
            alt=""
            className={classes.rightImage}
          />
        </Box>
      </Box>
    </BackgroundImage>
  );
};

export default HeroBanner;
