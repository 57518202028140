import { Box, Grid } from "@mantine/core";
import React, { Fragment, memo } from "react";
import { MTypography } from "../../../ui";
import { Link } from "react-router-dom";
import classes from "./FBottom.module.scss";
import { footerLink } from "../../../constant/Constant";
import { FiFacebook, FiYoutube } from "react-icons/fi";
import { FaRedditAlien, FaInstagram } from "react-icons/fa";
import { TbBrandMedium, TbBrandTelegram } from "react-icons/tb";
import { RiTwitterXFill } from "react-icons/ri";
import { CiLinkedin } from "react-icons/ci";
import { COLORS } from "../../../assets";

const FBottom = () => {
  return (
    <Grid mt={20}>
      <Grid.Col span={{ base: 6, xs: 3, sm: 3, md: 3, lg: 2.4 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Quick Links
          </MTypography>
          <Fragment>
            {footerLink.quickLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 3, sm: 3, md: 3, lg: 2.4 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Policy
          </MTypography>

          <Fragment>
            {footerLink.policyLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 3, sm: 3, md: 3, lg: 2.4 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Company
          </MTypography>
          <Fragment>
            {footerLink.companyLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 6, xs: 3, sm: 3, md: 3, lg: 2.4 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Support
          </MTypography>
          <Fragment>
            {footerLink.supportLink.map((item) => (
              <Link key={item.label} to={item.link} className={classes.link}>
                {item?.label}
              </Link>
            ))}
          </Fragment>
        </Box>
      </Grid.Col>
      <Grid.Col span={{ base: 12, xs: 12, sm: 12, md: 12, lg: 2.4 }}>
        <Box>
          <MTypography
            variant="description_18"
            tag="h3"
            marginBottom={8}
            color="white"
          >
            Follow US
          </MTypography>
          <Box className={classes.socialMediaContainer}>
            <Box className={classes.socialMediaBox}>
              <FiFacebook color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <RiTwitterXFill color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <CiLinkedin color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <FiYoutube color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <FaRedditAlien color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <FaInstagram color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <TbBrandMedium color={COLORS.white} />
            </Box>
            <Box className={classes.socialMediaBox}>
              <TbBrandTelegram color={COLORS.white} />
            </Box>
          </Box>
        </Box>
      </Grid.Col>
    </Grid>
  );
};

export default memo(FBottom);
