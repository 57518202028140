import React, { memo } from "react";
import classes from "./playStore.module.scss";
import { Box, Grid } from "@mantine/core";
import { IMAGES } from "../../../assets/image";
import { MTypography } from "../../../ui";
import { COLORS } from "../../../assets";

const PlayStore = () => {
  return (
    <div className={classes.root}>
      <Grid align="center">
        <Grid.Col span={{ base: 12, md: 6 }}>
          <img src={IMAGES.mobile_play_storeImg} alt="" width={400} />
        </Grid.Col>
        <Grid.Col span={{ base: 12, md: 6 }}>
          <Box>
            <Box>
              <Box>
                <MTypography variant="subHeading_32" tag="p" fontWeight={600}>
                  Driving through the{" "}
                  <span style={{ color: COLORS.primary }}>
                    Future of Money!
                  </span>
                </MTypography>
              </Box>
              <Box my={24}>
                <MTypography variant="description_16" tag="h3" fontWeight={300}>
                  Join Us Today
                </MTypography>
              </Box>
              <Box mb={10}>
                <MTypography variant="description_16" tag="h2" fontWeight={600}>
                  Scan to download{" "}
                  <span style={{ color: COLORS.primary }}>Koinpark App</span>
                </MTypography>
              </Box>
            </Box>
            <Box>
              <Grid>
                <Grid.Col span={{ base: 6, sm: 4, md: 5, lg: 4 }}>
                  <Box>
                    <MTypography
                      variant="description_16"
                      tag="h3"
                      fontWeight={300}
                      marginBottom={10}
                    >
                      Android
                    </MTypography>
                    <img src={IMAGES.qr_play_store} alt="" width={148} />
                  </Box>
                </Grid.Col>
                <Grid.Col span={{ base: 6, sm: 4, md: 5, lg: 4 }}>
                  {" "}
                  <Box>
                    <MTypography
                      variant="description_16"
                      tag="h3"
                      fontWeight={300}
                      marginBottom={10}
                    >
                      IOS
                    </MTypography>
                    <img src={IMAGES.qr_play_store} alt="" width={148} />
                  </Box>
                </Grid.Col>
              </Grid>
            </Box>
            <Box>
              <Grid justify="end" mt={20}>
                <Grid.Col span={{ base: 6, sm: 3, md: 4, lg: 3 }}>
                  <a href="/">
                    <img
                      src={IMAGES.google_store_vertical}
                      alt=""
                      width={112}
                    />
                  </a>
                </Grid.Col>
                <Grid.Col span={{ base: 6, sm: 3, md: 4, lg: 3 }}>
                  {" "}
                  <a href="/">
                    <img src={IMAGES.apple_store_vertical} alt="" width={112} />
                  </a>
                </Grid.Col>
              </Grid>
            </Box>
          </Box>
        </Grid.Col>
      </Grid>
    </div>
  );
};

export default memo(PlayStore);
