import React, { memo } from "react";

import classes from "./NewsLetter.module.scss";
import { MTypography, MInputFelid } from "../../../ui";
import { Box } from "@mantine/core";

const NewsLetter = () => {
  return (
    <div className={classes.root}>
      <MTypography
        variant="subHeading_26"
        tag="h3"
        fontWeight={600}
        marginBottom={8}
      >
        Get in Touch with Us!
      </MTypography>
      <MTypography
        variant="description_16"
        tag="h5"
        fontWeight={300}
        marginBottom={8}
        color="gray_light"
      >
        Join the Koinpark Community to get the latest news and releases...
      </MTypography>
      <Box>
        <MInputFelid placeholder={`Recipient's username`} />
      </Box>
    </div>
  );
};

export default memo(NewsLetter);
